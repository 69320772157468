import React from 'react'

import MainLayout from '../../layouts/MainLayout/MainLayout'
import SEO from '../../elements/SEO/SEO'
import Heading from '../../elements/Heading/Heading'
import Text from '../../elements/Text/Text'
import Section from '../../components/Section/Section'
import Grid from '@material-ui/core/Grid'

const AboutPage = () => (
  <MainLayout isHeaderTransparent>
    <SEO title="About" />
    <Section isBackgroundGradient padding="xxLarge 0 xLarge">
      <Heading level={1} margin="0 0 large" color="white">
        About enquire<strong>hub</strong>
      </Heading>
      <Heading level={2} color="white" margin="0 0 medium">
        Our Mission
      </Heading>
      <Grid container>
        <Grid item sm={10} md={8}>
          <Text color="white">
            Visibility and communication are central for business growth and
            success. enquirehub is an upcoming global business platform,
            cost-free for all to use and designed to connect businesses. Users
            can easily sign up and create a profile without hassle, as well as
            browse and access business information anytime, anywhere.
          </Text>
          <Text color="white">
            At present, finding business information can be difficult and
            time-consuming. Websites are inconsistent in quality, while for
            directories, the majority are either upscale, or limited by regions
            and countries. Most social media outlets are constructed to promote
            an individual; overall there is a lack of emphasis on the businesses
            themselves. enquirehub strives to change this, bringing attention on
            the business. We believe creating a hub and standardising business
            information gives users the chance to compare across markets more
            easily and make better decisions. Our goal is to bridge the distance
            between customers and businesses through building a universal
            platform for communication.
          </Text>
          <Text color="white">
            enquirehub is safe and secure; with our privacy policy and 256-bit
            SSL encryption in place you can be sure your data is stored securely
            from end-to-end. Ultimately, there isn’t an easier way to search and
            promote businesses on a global scale than enquirehub.
          </Text>
        </Grid>
      </Grid>
    </Section>
    {/*<Section padding="medium">*/}
    {/*  <Heading level={4} weight="regular">*/}
    {/*    "There are no secrets to success. It is the result of preparation, hard*/}
    {/*    work and learning from failure."*/}
    {/*  </Heading>*/}
    {/*  <Text margin="0" align="right" size="xLarge">- Colin Powell</Text>*/}
    {/*</Section>*/}
  </MainLayout>
)

export default AboutPage
